<template>
  <a-select
    show-search
    :placeholder="pretty"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    option-label-prop="title"
    size="large"
    @search="onChange"
    @change="onSelect">
    <a-select-option v-for="(result, i) in results" :key="`${i}`" :title="result.structured_formatting.main_text">
      <div class="d-flex  align-items-start">
        <div class="mt-1 mr-2">
          <a-icon class="icon text-secondary font-size-16" type="environment"/>
        </div>
        <div class="ms-2 me-auto suggestion">
          <div class="font-size-14">
            {{ result.structured_formatting.main_text }}
          </div>
          <span class="font-size-14 text-muted fw-lighter">
            {{ result.structured_formatting.secondary_text }}
          </span>
        </div>
      </div>
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  name: 'JAddress',
  props: ['value'],
  data() {
    return {
      results: [],
    }
  },
  computed: {
    address: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      },
    },
    pretty() {
      return this.address.street
    },
  },
  methods: {
    onSelect(value) {
      const placeId = this.results[value].place_id
      this.$geocoder.geocode({ placeId: placeId })
        .then(({ results }) => {
          if (results[0]) {
            const address = this.parse(results[0].address_components)
            address.street = address.number ? `${address.street} ${address.number}` : address.street
            address.lat = results[0].geometry.location.lat()
            address.lng = results[0].geometry.location.lng()
            this.address = address
          }
        })
    },
    onChange(value) {
      if (value) {
        const bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(-34.98791313093095, -59.02533164263855),
          new window.google.maps.LatLng(-34.29836285949103, -57.76613832237943))

        this.$autocomplete.getPlacePredictions({
          input: value,
          componentRestrictions: { country: ['ar'] },
          types: ['address'],
          bounds,
        }, this.addSuggestions)
      } else {
        this.address = null
      }
    },
    addSuggestions (predictions, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        this.results = predictions
      } else {
        this.results = []
      }
    },
    parse(components) {
      var ShouldBeComponent = {
        number: ['street_number'],
        zip: ['postal_code'],
        street: ['street_address', 'route'],
        state: [
          'administrative_area_level_1',
          'administrative_area_level_2',
          'administrative_area_level_3',
          'administrative_area_level_4',
          'administrative_area_level_5',
        ],
        city: [
          'locality',
          'sublocality',
          'sublocality_level_1',
          'sublocality_level_2',
          'sublocality_level_3',
          'sublocality_level_4',
        ],
        locality: [
          'locality',
          'sublocality',
          'sublocality_level_1',
          'sublocality_level_2',
          'sublocality_level_3',
          'sublocality_level_4',
        ],
        country: ['country'],
      }
      var address = {}
      components.forEach(component => {
        for (var shouldBe in ShouldBeComponent) {
          if (ShouldBeComponent[shouldBe].some(item => component.types.includes(item))) {
            if (shouldBe === 'country') {
              address[shouldBe] = component.short_name
            } else {
              address[shouldBe] = component.long_name
            }
          }
        }
      })
      return address
    },
  },
}
</script>
<style scoped>
.icon {
  vertical-align: 0em;
}
.suggestion {
  font-size: 12px;
}
</style>
