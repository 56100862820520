<template>
  <j-abm
    name="courier"
    :suppliers="suppliers"
    :features="{
      create: true,
      update: true,
      delete: false,
    }"
    @create="onCreate"
    @update="onUpdate">
    <template #form="{item}">
      <!-- General -->
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('general') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
          ref="general"
          :model="item"
          :rules="rules">
          <j-enum-radio
            prop="type"
            name="courierType"
            v-model="item.type" />
          <j-enum-radio
            prop="vehicle"
            v-model="item.vehicle" />
          <j-input
            prop="company"
            v-model="item.company"
            icon="bank" />
        </a-form-model>
      </div>
      <a-divider />
      <!-- Address -->
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('address') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
          ref="address"
          :model="item"
          :rules="rules">
          <j-field prop="address">
            <j-address v-model="item.address" />
          </j-field>
          <j-input
            prop="apartment"
            v-model="item.address.apartment"
            icon="bell" />
        </a-form-model>
      </div>
      <a-divider />
      <!-- Contact -->
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('contact') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
            ref="contact"
            :model="item.contact"
            :rules="rules">
          <j-input
            prop="name"
            v-model="item.contact.name"
            icon="user" />
          <j-input
            prop="phone"
            v-model="item.contact.phone"
            :format="f.phone"
            icon="phone" />
          <j-input
            prop="email"
            v-model="item.contact.email"
            :format="f.lower"
            icon="mail" />
          <j-input
            prop="identification"
            v-model="item.contact.identification"
            icon="idcard" />
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JField from '@/views/shared/forms/field'
import JInput from '@/views/shared/forms/input'
import JAddress from '@/views/shared/forms/address'
import JEnumRadio from '@/views/shared/forms/enum-radio'
export default {
  components: {
    JAbm,
    JInput,
    JField,
    JEnumRadio,
    JAddress,
  },
  data: function () {
    return {
      f,
      acl,
      rules: {
        vehicle: [v.required],
        type: [v.required],
        address: [v.required, v.address],
        phone: [v.required, v.phone],
        email: [v.required, v.email],
        name: [v.required],
        identification: [v.required],
      },
    }
  },
  computed: {
    suppliers() {
      return {
        fetchOne: id => lms.courier.fetchOne(id),
        payload: () => {
          return {
            type: 'SINGLE',
            vehicle: 'CAR',
            contact: {},
            address: {},
          }
        },
      }
    },
  },
  methods: {
    onCreate(courier) {
      this.onSubmit(courier, courier => lms.courier.create(courier))
    },
    onUpdate(courier) {
      this.onSubmit(courier, courier => lms.courier.update(courier))
    },
    onSubmit(courier, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.general.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.address.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.contact.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(courier).then(_ => this.$router.push('/couriers'))
        }
      })
    },
  },
}
</script>
